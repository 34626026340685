import { createRouter, createWebHistory } from "vue-router";
import Beranda from "../views/Beranda.vue";
import Tentang from "../views/Tentang.vue";
import Program from "../views/Program.vue";
import MainProgram from "../components/Program/MainProgram.vue";
import DetailProgram from "../components/Program/DetailProgram.vue";
import Produk from "../components/Produk/Produk.vue";
import MainProduk from "../components/Produk/MainProduk.vue";
import DetailProduk from "../components/Produk/DetailProduk.vue";
import Kontak from "../views/Kontak.vue";
import Sertifikat from "../views/Sertifikat.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/",
    name: "Beranda",
    component: Beranda,
  },
  {
    path: "/tentang",
    name: "Tentang",
    component: Tentang,
  },
  {
    path: "/program",
    name: "Program",
    component: Program,
    children: [
      {
        path: "",
        name: "MainProgram",
        component: MainProgram,
      },
      {
        path: "detail/:id/:slug",
        name: "DetailProgram",
        component: DetailProgram,
      },
    ],
    props: true,
  },
  {
    path: "/produk",
    name: "Produk",
    component: Produk,
    children: [
      {
        path: "",
        name: "MainProduk",
        component: MainProduk,
      },
      {
        path: "detail/:id/:slug",
        name: "DetailProduk",
        component: DetailProduk,
      },
    ],
    props: true,
  },
  {
    path: "/kontak",
    name: "Kontak",
    component: Kontak,
  },
  {
    path: "/sertifikat/:id",
    name: "Sertifikat",
    component: Sertifikat,
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
