<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light py-0">
      <div class="container-fluid bottom-sh">
        <a class="navbar-brand ms-5 py-4" href="#" v-on:click="go('/');">
          <img src="../../assets/logo.png" height="75" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end me-6" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="#" v-on:click="go('/');" :class="{ active: $route.path == '/' }">Beranda</a>
            </li>
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="#" v-on:click="go('/tentang');" :class="{ active: $route.path == '/tentang' }">Tentang</a>
            </li> 
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="#" v-on:click="go('/program');" :class="{ active: $route.path == '/program' || subIsActive('/program')}">Program</a>
            </li>
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="#" v-on:click="go('/kontak');" :class="{ active: $route.path == '/kontak' }">Kontak</a>
            </li>
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="https://wbs.kemdikbud.go.id" style="font-weight: bolder;" target="_blank">WBS</a>
            </li>
            <li class="nav-item px-3">
              <a class="nav-link f-hh" href="https://www.lapor.go.id" style="font-weight: bolder;" target="_blank">SpanLapor</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'Header',
  mounted(){
    $('#navbarSupportedContent a').click(function(){
      $('#navbarSupportedContent').removeClass('show');
    });
  },
  methods: {
    go(link){
      this.$router.push(link);
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
      return this.$route.path.indexOf(path) === 0 // current path starts with this path string
    })
    }
  }
}
</script>